import { useFormContext, Controller } from 'react-hook-form';
import styled from 'styled-components';
import { Text } from '@/components/new/Text';
import { TextField } from '@/components/new/TextField';
import { FormError } from '@/components/ui/FormError';
import { RegistrationTable } from '@/components/ui/RegistrationTable';
import { RegistrationTableRow } from '@/components/ui/RegistrationTableRow';
import { RegistrationFirstCardSettingsData } from './schema';

const Wrapper = styled.div`
  margin-bottom: 32px;
`;

export const RegistrationFirstCardSettings = (): JSX.Element => {
  const {
    formState: { errors },
    control,
  } = useFormContext<RegistrationFirstCardSettingsData>();

  return (
    <Wrapper>
      <RegistrationTable header="新規カード設定">
        <RegistrationTableRow title="カード名義人">
          <div>
            <Controller
              control={control}
              name="name"
              render={({ field }) => (
                <TextField
                  aria-label="name"
                  placeholder="例）TARO YAMADA"
                  {...field}
                  onChange={(e) => field.onChange(e.target.value.toUpperCase())}
                  value={field.value ?? ''}
                />
              )}
            />
            <Text color="notes">半角英数字、半角スペースが使用可</Text>
          </div>
          {errors.name && <FormError>{errors.name.message}</FormError>}
        </RegistrationTableRow>
      </RegistrationTable>
    </Wrapper>
  );
};
