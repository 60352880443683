import NextLink, { LinkProps } from 'next/link';
import styled from 'styled-components';

export type Props = React.PropsWithChildren<LinkProps> & {
  target?: string;
  className?: string;
  onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
  disabled?: boolean;
};

const StyledLink = styled.a`
  text-decoration: none;
  color: #3b7de9;
`;

const DisabledLink = styled.a`
  text-decoration: none;
  color: #7c8291;
  cursor: not-allowed;
  opacity: 0.3;
`;

export const Link = ({
  target,
  onClick,
  children,
  className,
  disabled,
  ...rest
}: Props): JSX.Element => {
  if (disabled) {
    return (
      // https://w3c.github.io/html-aria/#el-a
      // https://w3c.github.io/html-aria/#el-a-no-href
      <DisabledLink>{children}</DisabledLink>
    );
  }

  return (
    // passHref
    // https://nextjs.org/docs/api-reference/next/link#if-the-child-is-a-custom-component-that-wraps-an-a-tag
    <NextLink {...rest} passHref>
      <StyledLink {...{ target, onClick, className }}>{children}</StyledLink>
    </NextLink>
  );
};
