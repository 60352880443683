import { yup } from '@/lib/yup';

export type TenantUserData = yup.Asserts<typeof tenantUserSchema>;

export const tenantUserSchema = yup.object({
  tenantUser: yup
    .object({
      label: yup.string().required(),
      value: yup.string().required(),
    })
    .typeError('ユーザーを指定してください')
    .required('ユーザーを指定してください'),
});
