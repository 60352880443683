import logo from '@images/logo.svg';
import logoAndroidStore from '@images/logoAndroidStore.svg';
import logoAppleStore from '@images/logoAppleStore.svg';
import logoBizPay from '@images/logoBizPay.svg';
import qrCodeAndroid from '@images/qrCodeAndroid.svg';
import qrCodeApple from '@images/qrCodeApple.svg';
import styled from 'styled-components';
import { Text } from '@/components/new/Text';
import { Image } from '@/components/ui/Image';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  background-color: #ffffff;
  height: 420px;
  padding: 0 12px;
`;

const QRRow = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const Separator = styled.div`
  border: 1px solid #d4d8dd;
  height: 160px;
`;

const StoreSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 24px;
`;

export const AppStore = (): JSX.Element => (
  <Wrapper>
    <Image src={logoBizPay} width={87} height={87} alt="logoBizPay" />
    <Image
      src={logo}
      width={238}
      height={20}
      alt="マネーフォワード Pay for Business"
    />
    <QRRow>
      <StoreSection>
        <Image src={qrCodeApple} width={130} height={130} alt="qrCodeApple" />
        <a
          href="https://apps.apple.com/jp/app/id1573194490"
          target="_blank"
          rel="noreferrer"
        >
          <Image
            src={logoAppleStore}
            width={133}
            height={38.25}
            alt="logoAppleStore"
          />
        </a>
      </StoreSection>
      <Separator />
      <StoreSection>
        <Image
          src={qrCodeAndroid}
          width={130}
          height={130}
          alt="qrCodeAndroid"
        />
        <a
          href="https://play.google.com/store/apps/details?id=com.moneyforward.bizpay"
          target="_blank"
          rel="noreferrer"
        >
          <Image
            src={logoAndroidStore}
            width={135}
            height={40}
            alt="logoAndroidStore"
          />
        </a>
      </StoreSection>
    </QRRow>
    <Text color="notes">
      Apple、Appleのロゴは、米国もしくはその他の国や地域におけるApple
      Inc.の商標です。App Storeは、Apple Inc.のサービスマークです。Google Play
      および Google Play ロゴは、Google LLC の商標です。
    </Text>
  </Wrapper>
);
