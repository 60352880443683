import styled from 'styled-components';
import { GlobalMessage } from '../GlobalMessage';

const Layout = styled.div`
  padding-top: 157px;
  padding-bottom: 32px;
  width: 800px;
  margin-left: auto;
  margin-right: auto;
`;

export type Props = {
  children: React.ReactNode;
};

export const LoginLayout = ({ children }: Props): JSX.Element => (
  <Layout>
    <GlobalMessage />
    {children}
  </Layout>
);
