import { useFormState } from 'react-hook-form';
import styled from 'styled-components';
import { FlashMessage } from '@/components/new/FlashMessage';

type Props = {
  message?: string;
};
const Box = styled.div`
  margin-bottom: 18px;
`;

export const RegistrationAlert = ({
  message = '正しく入力されていない項目があるようです。メッセージをご確認の上、再度入力してください。',
}: Props): JSX.Element | null => {
  const { errors } = useFormState();

  if (Object.keys(errors).length === 0) return null;

  return (
    <Box>
      <FlashMessage color="error">{message}</FlashMessage>
    </Box>
  );
};
