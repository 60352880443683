import { differenceInDays } from 'date-fns';
import { yup } from '@/lib/yup';

export type ResidencePeriodEndDateData = yup.Asserts<
  typeof residencePeriodEndDateSchema
>;

export const residencePeriodEndDateFieldSchema = yup
  .string()
  .iso8601Date()
  .required()
  .test('isFutureDate', '正しい日付を入力してください', (value) => {
    const timestamp = Date.parse(value);
    if (isNaN(timestamp)) return false;
    const now = new Date();
    const input = new Date(timestamp);
    return differenceInDays(input, now) >= 0;
  });

export const residencePeriodEndDateSchema = yup.object({
  residencePeriodEndDate: residencePeriodEndDateFieldSchema,
});
