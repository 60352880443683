import styled from 'styled-components';
import { Button } from '@/components/new/Button';
import { Text } from '@/components/new/Text';

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  height: 230px;
  background: white;
  border-radius: 4px;
  box-sizing: border-box;
`;

const Title = styled(Text)``;

const Body = styled(Text)`
  text-align: center;
`;

type Props = {
  deleteAccountManager: () => Promise<void>;
};

export const RegistrationAccountManagerDelete = ({
  deleteAccountManager,
}: Props): JSX.Element => {
  const handleDelete = async () => {
    const confirmed = confirm(
      '本人確認書類および容貌の撮影データも削除されます。\n削除してもよろしいですか？'
    );

    if (!confirmed) return;

    await deleteAccountManager();
  };

  return (
    <Layout>
      <Title>取引担当者情報は他のユーザーによって入力済みです</Title>
      <Body>
        取引担当者情報は本人しか編集できません。
        <br />
        編集するには、取引担当者情報を再登録する必要があります。
        <br />
        本人確認書類および容貌の撮影データも削除されます。
      </Body>
      <Button type="button" onClick={handleDelete}>
        取引担当者情報を再登録
      </Button>
    </Layout>
  );
};
