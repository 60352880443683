import { useFormContext } from 'react-hook-form';
import { TextField } from '@/components/new/TextField';
import { FormError } from '@/components/ui/FormError/FormError';
import { ResidencePeriodEndDateData } from './schema';

export const ResidencePeriodEndDateInput = (): JSX.Element => {
  const {
    register,
    formState: { errors },
  } = useFormContext<ResidencePeriodEndDateData>();

  return (
    <div>
      <TextField
        aria-label="在留期間（満了日）"
        {...register('residencePeriodEndDate')}
        placeholder="例）1960/06/06"
      />
      {errors.residencePeriodEndDate && (
        <FormError>{errors.residencePeriodEndDate.message}</FormError>
      )}
    </div>
  );
};
