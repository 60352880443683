import { Block } from '@moneyforward/cloud-react-ui';
import styled, { css } from 'styled-components';
import { Button } from '@/components/new/Button';
import { CircularProgress } from '@/components/new/CircularProgress';
import { Link } from '@/components/new/Link';
import { Text } from '@/components/new/Text';

export type PointBalanceProps = {
  pointBalance?: number | null;
  loading: boolean;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const StyledBlock = styled(Block)`
  padding: 20px;
  display: flex;
  align-items: center;
`;

const Label = styled(Text)`
  font-weight: 500;
  color: #666;
  margin-right: 8px;
`;

type PointProps = {
  loading: boolean;
};

const Point = styled.div<PointProps>`
  font-size: 30px;
  font-weight: 500;
  display: inline-flex;
  align-items: baseline;
  position: relative;
  top: -2px;

  ${({ loading }) =>
    loading &&
    css`
      visibility: hidden;
    `}
`;

const PointUnit = styled.span`
  font-size: 14px;
  position: relative;
  margin-left: 4px;
  top: -1px;
`;

const Box = styled.div`
  margin-left: auto;
`;

const PointBalanceBlock = ({ pointBalance, loading }: PointBalanceProps) => (
  <StyledBlock border>
    <Label>ポイント残高：</Label>

    {loading && <CircularProgress size="large" />}

    <Point loading={loading}>
      {pointBalance?.toLocaleString() ?? '-'}
      <PointUnit>ポイント</PointUnit>
    </Point>

    <Box>
      <Link href="/wallet/points/transfer">
        <Button
          type="button"
          variant="outline"
          disabled={(pointBalance ?? 0) < 100}
        >
          ポイント振替
        </Button>
      </Link>
    </Box>
  </StyledBlock>
);

export const PointBalance = (props: PointBalanceProps): JSX.Element => (
  <Container>
    <PointBalanceBlock {...props} />

    <Box>
      <Text color={'notes'} size={'small'}>
        100ポイント単位で振替可能です
      </Text>
    </Box>
  </Container>
);
