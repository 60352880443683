import styled from 'styled-components';
import { Button } from '@/components/new/Button';

const ButtonWrapper = styled.div({
  display: 'inline-block',
  marginRight: '16px',
  '> button': {
    width: '160px',
  },
});

export const Invite = (): JSX.Element => (
  <ButtonWrapper>
    <Button type="submit" size="large">
      招待メールを送る
    </Button>
  </ButtonWrapper>
);
