import styled from 'styled-components';

const Title = styled.h1`
  margin: 0;
  padding: 16px 0;
  font-size: 16px;
  text-align: center;
  border-bottom: 1px solid #dce0e6;
`;

type Props = {
  children: string;
};

export const LoginTitle = ({ children }: Props): JSX.Element => (
  <Title>{children}</Title>
);
