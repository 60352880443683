import { differenceInYears } from 'date-fns';
import { yup } from '@/lib/yup';

export type BirthDateData = yup.Asserts<typeof birthDateSchema>;

export const birthDateSchema = yup.object({
  birthDate: yup
    .string()
    .iso8601Date()
    .required()
    .when('$confirmAge', (confirmAge, schema) =>
      confirmAge
        ? schema.test(
            'age',
            '成年（18歳以上）である必要があります',
            (value: string) => {
              return differenceInYears(new Date(), new Date(value)) >= 18;
            }
          )
        : schema
    ),
});
