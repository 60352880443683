import styled from 'styled-components';
import { RegistrationTable } from '@/components/ui/RegistrationTable';
import { TenantUserInput } from '@/components/ui/inputs/TenantUser/TenantUserInput';
import { addUserUrl } from '@/lib/navis';
import { NavisSsoLink } from '../NavisSsoLink';
import { RegistrationTableRow } from '../RegistrationTableRow';

const Box = styled.div`
  margin-bottom: 8px;
`;

export const RegistrationAccountManagerInvite = (): JSX.Element => {
  const link = new URL(addUserUrl);

  return (
    <RegistrationTable header="マネーフォワード Pay for Business のユーザーを招待する">
      <RegistrationTableRow title="表示名">
        <Box>
          ユーザーが存在しない場合は
          <NavisSsoLink link={link} />
          から追加してください。
        </Box>
        <TenantUserInput />
      </RegistrationTableRow>
    </RegistrationTable>
  );
};
