import styled from 'styled-components';
import { Button } from '@/components/new/Button';
import { useGoToStepsOrConfirmation } from '@/hooks';

const ButtonWrapper = styled.div({
  display: 'inline-block',
  marginRight: '16px',
  '> button': {
    width: '160px',
  },
});

export const Cancel = (): JSX.Element => {
  const goToStepsOrConfirmation = useGoToStepsOrConfirmation();

  const handleClick = (): void => {
    goToStepsOrConfirmation();
  };

  return (
    <ButtonWrapper>
      <Button
        variant="outline"
        size="large"
        type="button"
        onClick={handleClick}
      >
        キャンセル
      </Button>
    </ButtonWrapper>
  );
};
