import React from 'react';
import styled from 'styled-components';
import { RegistrationLayout } from '@/components/ui/RegistrationLayout';

export type Props = {
  children: React.ReactNode;
};

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
`;

export const RegistrationStepLayout = ({ children }: Props): JSX.Element => (
  <RegistrationLayout>
    <Wrapper>{children}</Wrapper>
  </RegistrationLayout>
);
