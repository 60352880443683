import styled from 'styled-components';

export type Props = {
  entered?: boolean;
};

const Wrapper = styled.section`
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
  grid-template-areas: 'link . .';
  padding: 0 1em;
`;
const Link = styled.div<{ entered: Props['entered'] }>`
  width: 8px;
  height: 16px;
  grid-area: link;
  justify-self: center;
  background: ${({ entered }) => (entered ? '#7c8291' : '#D4D8DD')};
`;

export const RegistrationStepLink = ({ entered }: Props): JSX.Element => (
  <Wrapper>
    <Link entered={entered} role="separator" />
  </Wrapper>
);
