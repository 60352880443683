import styled from 'styled-components';

export const LoginBlock = styled.div`
  background-color: #fff;
  border-radius: 4px;
`;

export type Props = {
  children: React.ReactNode;
};
