import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import { Table } from '@/components/new/Table';
import { TextField } from '@/components/new/TextField';
import { FormError } from '@/components/ui/FormError';
import { TransferPointFormData } from './schema';

type Props = {
  pointBalance: number;
};

const PointBalance = styled.p`
  font-size: 13px;
  margin: 0 0 8px;
`;

const AmountField = styled.div`
  display: inline-block;
  width: 128px;
  margin-right: 8px;
`;

const Point = styled.span``;

export const TransferPointForm = ({ pointBalance }: Props): JSX.Element => {
  const {
    register,
    formState: { errors },
  } = useFormContext<TransferPointFormData>();

  return (
    <Table.Root>
      <Table.Head>
        <Table.Row>
          <Table.ColumnHeaderCell colSpan={2}>
            ポイントを振り替える（1ポイント=1円）
          </Table.ColumnHeaderCell>
        </Table.Row>
      </Table.Head>
      <Table.Body>
        <Table.Row>
          <Table.RowHeaderCell width="160px">振替ポイント</Table.RowHeaderCell>
          <Table.Cell>
            <PointBalance>
              ポイント残高：
              {Number.isInteger(pointBalance) ? (
                <Point>{pointBalance.toLocaleString()}</Point>
              ) : (
                <Point data-testid="pointBalanceError">-</Point>
              )}
              ポイント
            </PointBalance>
            <AmountField>
              <TextField aria-label="transferPoint" {...register('amount')} />
            </AmountField>
            ポイント（100単位）
            {errors.amount && (
              <div>
                <FormError>{errors.amount.message}</FormError>
              </div>
            )}
          </Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table.Root>
  );
};
