import { Table } from '@/components/new/Table';

export type Props = {
  title: React.ReactNode;
  children: React.ReactNode;
};

export const RegistrationTableTextRow = ({
  title,
  children,
}: Props): JSX.Element => (
  <Table.Row>
    <Table.RowHeaderCell width="196px">{title}</Table.RowHeaderCell>
    <Table.Cell>{children}</Table.Cell>
  </Table.Row>
);
