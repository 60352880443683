import styled from 'styled-components';

export type Props = {
  children: React.ReactNode;
  className?: string;
};

const CenterLayout = styled.div`
  margin: 0 auto;
  width: 800px;
  padding-top: 32px;
  padding-bottom: 80px;
`;

export const RegistrationLayout = (props: Props): JSX.Element => (
  <CenterLayout {...props} />
);
