import styled from 'styled-components';
import { FlashMessage } from '@/components/new/FlashMessage';

const Wrapper = styled.div`
  margin-bottom: 24px;
`;

const Text = styled.p`
  margin: 0 0 16px 0;
`;

const Comment = styled.p`
  margin-bottom: 0;
  white-space: pre-wrap;
`;

const List = styled.ul`
  list-style: none;
  padding-left: 0;
  margin: 0;
`;

export const RegistrationRejectedReason = ({
  rejectedReasons,
  rejectedComment,
}: {
  rejectedReasons: string[];
  rejectedComment?: string | null;
}): JSX.Element | null => {
  if (rejectedReasons.length <= 0) {
    return null;
  }

  return (
    <Wrapper>
      <FlashMessage color="error">
        <Text>再申請が必要です。</Text>
        <List>
          {rejectedReasons.map((reason) => {
            return <li key={reason}>・{reason}</li>;
          })}
        </List>
        {rejectedComment && <Comment>{rejectedComment}</Comment>}
      </FlashMessage>
    </Wrapper>
  );
};
