import { useFormContext } from 'react-hook-form';
import { TextField } from '@/components/new/TextField';
import { FormError } from '@/components/ui/FormError';
import { LastNameEnData } from './schema';

export const LastNameEnInput = (): JSX.Element => {
  const {
    register,
    formState: { errors },
  } = useFormContext<LastNameEnData>();

  return (
    <div>
      <TextField
        aria-label="姓（英語表記）"
        {...register('lastNameEn')}
        placeholder="例）Yamada"
      />
      {errors.lastNameEn && <FormError>{errors.lastNameEn.message}</FormError>}
    </div>
  );
};
