import { ResidentialStatusEnum } from '@/graphql';

type Option = {
  value: ResidentialStatusEnum;
  label: string;
};

export const ResidentialStatusSelectOptions: Option[] = [
  {
    value: ResidentialStatusEnum.Diplomat,
    label: '外交',
  },
  {
    value: ResidentialStatusEnum.Official,
    label: '公用',
  },
  {
    value: ResidentialStatusEnum.Professor,
    label: '教授',
  },
  {
    value: ResidentialStatusEnum.Artist,
    label: '芸術',
  },
  {
    value: ResidentialStatusEnum.ReligiousActivities,
    label: '宗教',
  },
  {
    value: ResidentialStatusEnum.Journalist,
    label: '報道',
  },
  {
    value: ResidentialStatusEnum.HighlySkilledProfessionals,
    label: '高度専門職',
  },
  {
    value: ResidentialStatusEnum.BusinessManager,
    label: '経営・管理',
  },
  {
    value: ResidentialStatusEnum.LegalAccountingServices,
    label: '法律・会計業務',
  },
  {
    value: ResidentialStatusEnum.MedicalServices,
    label: '医療',
  },
  {
    value: ResidentialStatusEnum.Researcher,
    label: '研究',
  },
  {
    value: ResidentialStatusEnum.Instructor,
    label: '教育',
  },
  {
    value:
      ResidentialStatusEnum.EngineerSpecialistInHumanitiesInternationalServices,
    label: '技術・人文知識・国際業務',
  },
  {
    value: ResidentialStatusEnum.IntraCompanyTransferee,
    label: '企業内転勤',
  },
  {
    value: ResidentialStatusEnum.NursingCare,
    label: '介護',
  },
  {
    value: ResidentialStatusEnum.Entertainer,
    label: '興行',
  },
  {
    value: ResidentialStatusEnum.SkilledLabor,
    label: '技能',
  },
  {
    value: ResidentialStatusEnum.SpecifiedSkilledWorker,
    label: '特定技能',
  },
  {
    value: ResidentialStatusEnum.TechnicalInternTraining,
    label: '技能実習',
  },
  {
    value: ResidentialStatusEnum.CulturalActivities,
    label: '文化活動',
  },
  {
    value: ResidentialStatusEnum.TemporaryVisitor,
    label: '短期滞在',
  },
  {
    value: ResidentialStatusEnum.Student,
    label: '留学',
  },
  {
    value: ResidentialStatusEnum.Trainee,
    label: '研修',
  },
  {
    value: ResidentialStatusEnum.Dependent,
    label: '家族滞在',
  },
  {
    value: ResidentialStatusEnum.DesignatedActivities,
    label: '特定活動',
  },
  {
    value: ResidentialStatusEnum.PermanentResident,
    label: '永住者',
  },
  {
    value: ResidentialStatusEnum.SpouseOrChildOfJapaneseNational,
    label: '日本人の配偶者等',
  },
  {
    value: ResidentialStatusEnum.SpouseOrChildOfPermanentResident,
    label: '永住者の配偶者等',
  },
  {
    value: ResidentialStatusEnum.LongTermResident,
    label: '定住者',
  },
];

export const getResidentialStatusLabel = (
  value: ResidentialStatusEnum
): string =>
  ResidentialStatusSelectOptions.find((option) => option.value === value)
    ?.label ?? '';
