import { useFormContext } from 'react-hook-form';
import { TextField } from '@/components/new/TextField';
import { FormError } from '@/components/ui/FormError';
import { FirstNameEnData } from './schema';

export const FirstNameEnInput = (): JSX.Element => {
  const {
    register,
    formState: { errors },
  } = useFormContext<FirstNameEnData>();

  return (
    <div>
      <TextField
        aria-label="氏名（英語表記）"
        {...register('firstNameEn')}
        placeholder="例）Taro"
      />
      {errors.firstNameEn && (
        <FormError>{errors.firstNameEn.message}</FormError>
      )}
    </div>
  );
};
