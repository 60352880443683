import { useFormContext } from 'react-hook-form';
import { TextField } from '@/components/new/TextField';
import { FormError } from '@/components/ui/FormError/FormError';
import { FirstNameKanaData } from './schema';

export const FirstNameKanaInput = (): JSX.Element => {
  const {
    register,
    formState: { errors },
  } = useFormContext<FirstNameKanaData>();

  return (
    <div>
      <TextField
        aria-label="firstNameKana"
        {...register('firstNameKana')}
        placeholder="例）タロウ"
      />
      {errors.firstNameKana && (
        <FormError>{errors.firstNameKana.message}</FormError>
      )}
    </div>
  );
};
