import styles from './styles.module.css';

export type Props = {
  completed?: boolean;
};

const StepTie = ({ completed = false }: Props): JSX.Element => (
  <div className={styles.stepTieWrapper}>
    <div
      className={`${styles.stepTie} ${
        styles[completed ? 'completed' : 'waiting']
      }`}
    />
  </div>
);

export { StepTie };
