import { yup } from '@/lib/yup';
import { TransferPointInput } from '@/graphql';

export type TransferPointFormData = Pick<TransferPointInput, 'amount'>;

export const transferPointFormSchema: yup.ObjectSchema<TransferPointFormData> =
  yup.object({
    amount: yup
      .number()
      .positive('金額を入力してください')
      .min(100, '100ポイント以上の金額を入力してください')
      .when('$pointBalance', ([pointBalance], schema) =>
        schema.max(pointBalance, 'ポイント残高より小さい金額を入力してください')
      )
      .test('divide', '100ポイント単位で入力してください', function (value) {
        if (!value) return false;

        return value % 100 === 0;
      })
      .typeError('金額を入力してください')
      .stripCommas()
      .required('金額を入力してください'),
  });
