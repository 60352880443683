import { Prefecture } from '@/graphql';

export const PrefectureSelectOptions = [
  { value: Prefecture.Hokkaido, label: '北海道' },
  { value: Prefecture.Aomori, label: '青森県' },
  { value: Prefecture.Iwate, label: '岩手県' },
  { value: Prefecture.Miyagi, label: '宮城県' },
  { value: Prefecture.Akita, label: '秋田県' },
  { value: Prefecture.Yamagata, label: '山形県' },
  { value: Prefecture.Fukushima, label: '福島県' },
  { value: Prefecture.Ibaraki, label: '茨城県' },
  { value: Prefecture.Tochigi, label: '栃木県' },
  { value: Prefecture.Gunma, label: '群馬県' },
  { value: Prefecture.Saitama, label: '埼玉県' },
  { value: Prefecture.Chiba, label: '千葉県' },
  { value: Prefecture.Tokyo, label: '東京都' },
  { value: Prefecture.Kanagawa, label: '神奈川県' },
  { value: Prefecture.Niigata, label: '新潟県' },
  { value: Prefecture.Toyama, label: '富山県' },
  { value: Prefecture.Ishikawa, label: '石川県' },
  { value: Prefecture.Fukui, label: '福井県' },
  { value: Prefecture.Yamanashi, label: '山梨県' },
  { value: Prefecture.Nagano, label: '長野県' },
  { value: Prefecture.Gifu, label: '岐阜県' },
  { value: Prefecture.Shizuoka, label: '静岡県' },
  { value: Prefecture.Aichi, label: '愛知県' },
  { value: Prefecture.Mie, label: '三重県' },
  { value: Prefecture.Shiga, label: '滋賀県' },
  { value: Prefecture.Kyoto, label: '京都府' },
  { value: Prefecture.Osaka, label: '大阪府' },
  { value: Prefecture.Hyogo, label: '兵庫県' },
  { value: Prefecture.Nara, label: '奈良県' },
  { value: Prefecture.Wakayama, label: '和歌山県' },
  { value: Prefecture.Tottori, label: '鳥取県' },
  { value: Prefecture.Shimane, label: '島根県' },
  { value: Prefecture.Okayama, label: '岡山県' },
  { value: Prefecture.Hiroshima, label: '広島県' },
  { value: Prefecture.Yamaguchi, label: '山口県' },
  { value: Prefecture.Tokushima, label: '徳島県' },
  { value: Prefecture.Kagawa, label: '香川県' },
  { value: Prefecture.Ehime, label: '愛媛県' },
  { value: Prefecture.Kochi, label: '高知県' },
  { value: Prefecture.Fukuoka, label: '福岡県' },
  { value: Prefecture.Saga, label: '佐賀県' },
  { value: Prefecture.Nagasaki, label: '長崎県' },
  { value: Prefecture.Kumamoto, label: '熊本県' },
  { value: Prefecture.Oita, label: '大分県' },
  { value: Prefecture.Miyazaki, label: '宮崎県' },
  { value: Prefecture.Kagoshima, label: '鹿児島県' },
  { value: Prefecture.Okinawa, label: '沖縄県' },
];

export const getPrefectureLabel = (prefecture: Prefecture): string => {
  const result = PrefectureSelectOptions.find((option) => {
    return prefecture === option.value;
  });
  return result?.label ?? '';
};
