import { useRouter } from 'next/router';
import React, { useCallback } from 'react';
import { Button } from '@/components/new/Button';
import { StepCard, StepCardStatus } from '../StepCard';

export type Props = {
  step: string;
  status: StepCardStatus | 'unnecessary';
  title: string;
  subTitle: React.ReactNode;
  warning?: string;
};

export const RegistrationStepCard = ({
  step,
  status,
  title,
  subTitle,
  warning,
}: Props): JSX.Element | null => {
  const router = useRouter();

  const handleClick = useCallback(() => {
    router.push(`${router.pathname}/${step}`);
  }, []);

  if (status === 'unnecessary') {
    return null;
  }

  return (
    <StepCard
      status={status}
      title={title}
      content={subTitle}
      warning={warning}
      button={<StepCardButton handleClick={handleClick} status={status} />}
    />
  );
};

/*
 * ボタン
 */
function StepCardButton({
  handleClick,
  status,
}: {
  handleClick: () => void;
  status: StepCardStatus;
}): JSX.Element | null {
  switch (status) {
    case 'completed':
      return (
        <Button
          type="button"
          variant="outline"
          disabled={false}
          onClick={handleClick}
        >
          修正
        </Button>
      );
    case 'incomplete':
      return (
        <Button
          type="button"
          color="red"
          disabled={false}
          onClick={handleClick}
        >
          修正
        </Button>
      );
    case 'waiting':
      return (
        <Button type="button" disabled={false} onClick={handleClick}>
          進む
        </Button>
      );
    case 'disabled':
      return (
        <Button type="button" disabled={true} onClick={handleClick}>
          進む
        </Button>
      );
    default: {
      /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
      const _: never = status;
      return null as never;
    }
  }
}
