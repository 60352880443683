import styled from 'styled-components';
import { Text } from '@/components/new/Text';

export type Props = {
  children: React.ReactNode;
  center?: boolean;
};

const Wrapper = styled.div<{ center: Props['center'] }>`
  margin-bottom: 16px;
  ${({ center }) => center && `text-align: center;`}
`;

export const RegistrationNote = ({ center, ...rest }: Props): JSX.Element => (
  <Wrapper center={center}>
    <Text {...rest} />
  </Wrapper>
);
