import { addressSchema } from '@/components/ui/inputs/Address/schema';
import { birthDateSchema } from '@/components/ui/inputs/BirthDate/schema';
import { firstNameSchema } from '@/components/ui/inputs/FirstName/schema';
import { firstNameKanaSchema } from '@/components/ui/inputs/FirstNameKana/schema';
import { lastNameSchema } from '@/components/ui/inputs/LastName/schema';
import { lastNameKanaSchema } from '@/components/ui/inputs/LastNameKana/schema';
import { tenantUserSchema } from '@/components/ui/inputs/TenantUser/schema';
import { yup } from '@/lib/yup';
import { ResidentialStatusEnum } from '@/graphql';
import { firstNameEnSchema } from '../inputs/FirstNameEn';
import { lastNameEnSchema } from '../inputs/LastNameEn';
import { middleNameEnSchema } from '../inputs/MiddleNameEn';
import { residencePeriodEndDateFieldSchema } from '../inputs/ResidencePeriodEndDate/schema';
import { residentialStatusFieldSchema } from '../inputs/ResidentialStatus/schema';

export type RegistrationAccountManagerDetailsData = yup.Asserts<
  typeof registrationAccountManagerDetailsSchema
>;

export const registrationAccountManagerDetailsSchema = yup
  .object({
    isCorporateRepresentative: yup.string().oneOf(['true', 'false']).required(),
    position: yup.string().when('isCorporateRepresentative', {
      is: 'false',
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.ensure(), // todo apiの実装終わりましたら、もしstripの方がよければ、修正します
    }),
  })
  .concat(firstNameSchema)
  .concat(lastNameSchema)
  .concat(firstNameKanaSchema)
  .concat(lastNameKanaSchema)
  .concat(firstNameEnSchema)
  .concat(lastNameEnSchema)
  .concat(middleNameEnSchema)
  .concat(birthDateSchema)
  .concat(addressSchema)
  .concat(
    yup.object({
      nationalityId: yup.string().required('選択してください'),
      residentialStatus: yup
        .mixed<ResidentialStatusEnum>()
        .when('nationalityId', {
          is: 'JP',
          // NOTE: force transform to null because of the API spec
          then: (schema) => schema.nullable().transform(() => null),
          otherwise: () => residentialStatusFieldSchema,
        }),
      residencePeriodEndDate: yup.string().when('nationalityId', {
        is: 'JP',
        // NOTE: force transform to null because of the API spec
        then: (schema) => schema.nullable().transform(() => null),
        otherwise: () => residencePeriodEndDateFieldSchema,
      }),
    })
  );

export type RegistrationAccountManagerInviteData = yup.Asserts<
  typeof registrationAccountManagerInviteSchema
>;

export const registrationAccountManagerInviteSchema = tenantUserSchema;
