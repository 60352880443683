import { useFormContext, Controller } from 'react-hook-form';
import styled from 'styled-components';
import { Text } from '@/components/new/Text';
import { TextField } from '@/components/new/TextField';
import { FormError } from '@/components/ui/FormError';
import { RegistrationTable } from '@/components/ui/RegistrationTable';
import { RegistrationTableRow } from '@/components/ui/RegistrationTableRow';
import { TextWithQuestionIcon } from '@/components/ui/TextWithQuestionIcon';
import { TenantUserInput } from '@/components/ui/inputs/TenantUser/TenantUserInput';
import { addUserUrl } from '@/lib/navis';
import { NavisSsoLink } from '../NavisSsoLink';
import { RegistrationFirstCardLimitsData } from './schema';

const Wrapper = styled.div`
  margin-bottom: 1.5em;
`;

const TextFieldWrapper = styled.div`
  display: inline-block;
  width: 40%;
  margin-right: 2%;
`;

const Note = styled(Text)`
  margin-left: 9px;
  font-size: 12px;
  font-weight: 400;
`;

const Box = styled.div`
  margin-bottom: 8px;
`;

const TableHeader = (): JSX.Element => (
  <>
    初期設定<Note color="notes">※あとから変更可能です</Note>
  </>
);

export const RegistrationFirstCardLimits = (): JSX.Element => {
  const {
    formState: { errors },
    control,
  } = useFormContext<RegistrationFirstCardLimitsData>();
  const link = new URL(addUserUrl);

  const transformInput = (value: number) => {
    return isNaN(value) || value === 0 ? '' : value.toLocaleString();
  };

  const transformOutput = (value: string) => {
    const stripped = value.replace(/,/g, '');
    const output = parseInt(stripped, 10);
    return isNaN(output) ? 0 : output;
  };

  return (
    <Wrapper>
      <RegistrationTable header={<TableHeader />}>
        <RegistrationTableRow title="月ごとの上限金額">
          <Controller
            control={control}
            name="maximumMonthlyLimit"
            render={({ field }) => (
              <TextFieldWrapper>
                <TextField
                  aria-label="maximumMonthlyLimit"
                  {...field}
                  value={transformInput(field.value)}
                  onChange={(e) =>
                    field.onChange(transformOutput(e.target.value))
                  }
                />
              </TextFieldWrapper>
            )}
          />
          円
          {errors.maximumMonthlyLimit && (
            <section data-testid="maximumMonthlyLimitError">
              <FormError>{errors.maximumMonthlyLimit.message}</FormError>
            </section>
          )}
        </RegistrationTableRow>
        <RegistrationTableRow title="一回の上限金額">
          <Controller
            control={control}
            name="maximumPaymentLimit"
            render={({ field }) => (
              <TextFieldWrapper>
                <TextField
                  aria-label="maximumPaymentLimit"
                  {...field}
                  value={transformInput(field.value)}
                  onChange={(e) =>
                    field.onChange(transformOutput(e.target.value))
                  }
                />
              </TextFieldWrapper>
            )}
          />
          円
          {errors.maximumPaymentLimit && (
            <section data-testid="maximumPaymentLimitError">
              <FormError>{errors.maximumPaymentLimit.message}</FormError>
            </section>
          )}
        </RegistrationTableRow>
        <RegistrationTableRow
          title={
            <TextWithQuestionIcon
              label="カード利用者"
              content={
                <>
                  カードを利用する方を設定してください
                  <br />
                  決済の際に本人認証が必要となる場合があります
                </>
              }
            />
          }
        >
          <Box>
            ユーザーが存在しない場合は
            <NavisSsoLink link={link} />
            から追加してください。
          </Box>
          <TenantUserInput />
        </RegistrationTableRow>
      </RegistrationTable>
    </Wrapper>
  );
};
