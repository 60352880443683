import { useFormContext } from 'react-hook-form';
import { TextField } from '@/components/new/TextField';
import { FormError } from '@/components/ui/FormError/FormError';
import { useAutoKanaInput } from '@/hooks/useAutoKanaInput';
import { LastNameData } from './schema';

export const LastNameInput = ({
  updateValue,
}: {
  updateValue?: (value: string) => void;
}): JSX.Element => {
  const {
    register,
    formState: { errors },
  } = useFormContext<LastNameData>();

  const { onCompositionUpdate, onCompositionEnd, onInput } = useAutoKanaInput({
    updateFormValue: updateValue ? updateValue : () => {},
  });

  return (
    <div>
      <TextField
        aria-label="lastName"
        onCompositionUpdate={onCompositionUpdate}
        onCompositionEnd={onCompositionEnd}
        onInput={onInput}
        {...register('lastName')}
        placeholder="例）山田"
      />
      {errors.lastName && <FormError>{errors.lastName.message}</FormError>}
    </div>
  );
};
