import { createContext, forwardRef, useMemo } from 'react';
import styles from './styles.module.css';

export type Mode = 'number' | 'check';
export const StepsModeContext = createContext<{ mode: Mode }>({
  mode: 'check',
});

export type Props = {
  mode?: Mode;
  children: React.ReactNode;
};

const Steps = forwardRef<HTMLDivElement, Props>(
  ({ mode = 'check', ...rest }, ref) => {
    const value = useMemo(() => ({ mode }), [mode]);
    return (
      <StepsModeContext.Provider value={value}>
        <div ref={ref} className={styles.steps} {...rest} />
      </StepsModeContext.Provider>
    );
  }
);

Steps.displayName = 'Steps';

export { Steps };
