import circle from '@images/icons/circle.svg';
import circleCheck from '@images/icons/circleCheck.svg';
import circleExclamation from '@images/icons/circleExclamation.svg';
import React from 'react';
import styled, { css } from 'styled-components';
import { Image } from '@/components/ui/Image';

export type StepCardStatus =
  | 'waiting'
  | 'completed'
  | 'incomplete'
  | 'disabled';

export type Props = {
  status: StepCardStatus;
  title: string;
  content: React.ReactNode;
  warning?: string;
  button?: React.ReactNode;
};

export const Border = {
  completed: `2px solid #D4D8DD`,
  waiting: `2px solid #7C8291`,
  incomplete: `2px solid #EC4949`,
  disabled: `2px solid #D4D8DD`,
} as const;

const Wrapper = styled.section<{
  status: StepCardStatus;
}>`
  ${({ status }) => css`
    padding: 16px 14px 18px 21px;
    background-color: ${status === 'disabled' ? '#FAFAFA' : '#FFFFFF'};
    border: ${Border[status]};
    border-radius: 4px;
  `}
`;

const TitleWrapper = styled.section`
  display: flex;
  align-items: start;
`;

const Title = styled.span<{ status: StepCardStatus }>`
  ${({ status }) => css`
    padding: 7px 0 0 14px;
    font-weight: 500;
    font-size: 20px;
    line-height: 1em;
    color: ${status === 'disabled' ? '#666' : 'inherit'};
  `}
`;

const Warning = styled.section`
  color: #ec4949;
  font-size: 12px;
`;

const IconWrapper = styled.div`
  padding-top: 6px;
`;

const Box = styled.div`
  padding: 10px 0 0 38px;
`;

const ButtonWrapper = styled.div`
  margin-left: auto;
`;
export const StepCard = ({
  status,
  title,
  content,
  warning,
  button,
}: Props): JSX.Element => {
  return (
    <Wrapper status={status} data-testid="wrapper">
      <TitleWrapper>
        <IconWrapper>
          <StepCardIcon status={status} />
        </IconWrapper>

        <Title status={status}>{title}</Title>

        {button && <ButtonWrapper>{button}</ButtonWrapper>}
      </TitleWrapper>

      <Box>{content}</Box>
      {warning && (
        <Box>
          <Warning>{warning}</Warning>
        </Box>
      )}
    </Wrapper>
  );
};

const StepCardIcon = ({ status }: { status: StepCardStatus }): JSX.Element => {
  switch (status) {
    case 'completed':
      return <Image src={circleCheck} width={24} height={24} />;
    case 'incomplete':
      return <Image src={circleExclamation} width={24} height={24} />;
    case 'waiting':
    case 'disabled':
      return <Image src={circle} width={24} height={24} />;
    default: {
      /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
      const _: never = status;
      return null as never;
    }
  }
};
