import { CompositionEvent, useState } from 'react';

const hiraganaToKatakana = (str: string) => {
  return str.replace(/[\u3041-\u3096]/g, function (match) {
    const chr = match.charCodeAt(0) + 0x60;
    return String.fromCharCode(chr);
  });
};

type Props = {
  updateFormValue: (value: string) => void;
};

export const useAutoKanaInput = ({ updateFormValue }: Props) => {
  // value to display
  const [kana, setKana] = useState<string>('');

  const [kanjiInputValue, setKanjiInputValue] = useState<string>('');
  const [kanaInputValue, setKanaInputValue] = useState<string>('');

  // history of input value
  const [kanjiHistory, setKanjiHistory] = useState<string[]>([]);
  const [kanaHistory, setKanaHistory] = useState<string[]>([]);

  const onCompositionUpdate = (e: CompositionEvent<HTMLInputElement>) => {
    if (e.data.match(/^[\u3041-\u3096\u30FC]+$/)) {
      // set the value when the input is hiragana
      setKanaInputValue(e.data);
    }
  };

  const onCompositionEnd = (e: CompositionEvent<HTMLInputElement>) => {
    // input value from ompositionStart to compositionEnd
    const currentKanji = e.data;
    // input kana value from compositionStart to compositionEnd
    const currentKana = hiraganaToKatakana(kanaInputValue);

    // update history
    setKanaHistory((prev) => [...prev, currentKana]);
    setKanjiHistory((prev) => [...prev, currentKanji]);

    updateFormValue(kana + currentKana);

    // update value to display
    setKana((prev) => prev + currentKana);
    setKanjiInputValue(e.currentTarget.value);
  };

  const onInput = (e: React.FormEvent<HTMLInputElement>) => {
    if (kanaHistory.length === 0 || kanjiHistory.length === 0) return;

    const lastHistoryKanji = kanjiHistory[kanjiHistory.length - 1];
    const lastHisotryKana = kanaHistory[kanaHistory.length - 1];
    const currentValueLength = e.currentTarget.value.length;

    if (currentValueLength == 0) {
      setKana('');
      setKanjiInputValue('');
      setKanaHistory([]);
      setKanjiHistory([]);

      updateFormValue('');
      return;
    }

    if (
      currentValueLength + lastHistoryKanji.length ==
      kanjiInputValue.length
    ) {
      setKanjiInputValue((prev) => prev.slice(0, -lastHistoryKanji.length));

      // update history
      if (kanaHistory.join('') !== kana) {
        setKanaHistory([]);
        setKanjiHistory([]);
      } else {
        setKanjiHistory((prev) => prev.slice(0, prev.length - 1));
        setKanaHistory((prev) => prev.slice(0, prev.length - 1));
      }

      updateFormValue(kana.slice(0, -lastHisotryKana.length));

      // update value to display
      setKana((prev) => prev.slice(0, -lastHisotryKana.length));
    }
  };

  return {
    kana,
    onCompositionUpdate,
    onCompositionEnd,
    onInput,
  };
};
