import { useFormContext } from 'react-hook-form';
import { TextField } from '@/components/new/TextField';
import { FormError } from '@/components/ui/FormError/FormError';
import { useAutoKanaInput } from '@/hooks/useAutoKanaInput';
import { FirstNameData } from './schema';

export const FirstNameInput = ({
  updateValue,
}: {
  updateValue?: (value: string) => void;
}): JSX.Element => {
  const {
    register,
    formState: { errors },
  } = useFormContext<FirstNameData>();

  const { onCompositionUpdate, onCompositionEnd, onInput } = useAutoKanaInput({
    updateFormValue: updateValue ? updateValue : () => {},
  });

  return (
    <div>
      <TextField
        aria-label="firstName"
        onCompositionUpdate={onCompositionUpdate}
        onCompositionEnd={onCompositionEnd}
        onInput={onInput}
        {...register('firstName')}
        placeholder="例）太郎"
      />
      {errors.firstName && <FormError>{errors.firstName.message}</FormError>}
    </div>
  );
};
