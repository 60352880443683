import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import { TextField } from '@/components/new/TextField';
import { FormError } from '@/components/ui/FormError';
import { ActivateFormData } from './schema';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 196px 1fr;
  background-color: #fff;
  border: 1px solid #d4d8dd;
  border-radius: 4px;
`;

const Column = styled.div`
  padding: 8px;
  align-self: center;
`;

const Title = styled.span`
  font-size: 13px;
  color: #333;
  font-weight: 700;
`;

export const ActivateForm = (): JSX.Element => {
  const {
    register,
    formState: { errors },
  } = useFormContext<ActivateFormData>();

  return (
    <Wrapper>
      <Column>
        <Title>Web用認証コード</Title>
      </Column>
      <Column>
        <section>
          <TextField
            aria-label="activationCode"
            {...register('activationCode')}
          />
        </section>
        {errors.activationCode && (
          <section role="activationCodeError">
            <FormError>{errors.activationCode.message}</FormError>
          </section>
        )}
      </Column>
    </Wrapper>
  );
};
